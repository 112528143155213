import React from 'react'
import Seo from '../components/seo'
import Layout from '../components/layout'
import NextProject from '../components/next-project'
import { StaticImage } from 'gatsby-plugin-image'
import ScrollSpy from 'react-scrollspy-navigation'
import SpyButton from '../components/spy-button'
import ResultsIcon from '../images/iq-process/Icons_IQ_Process_1.svg'
import OnboardingIcon from '../images/iq-process/Icons_IQ_Process_2.svg'
import EstimationIcon from '../images/iq-process/Icons_IQ_Process_3.svg'
import TempoIcon from '../images/iq-process/Icons_IQ_Process_4.svg'
import ProductionIcon from '../images/iq-process/Icons_IQ_Process_5.svg'
import FramingIcon from '../images/iq-process/Icons_IQ_Process_6.svg'
import LiveDeployIcon from '../images/iq-process/Icons_IQ_Process_7.svg'

const IQProcess = ({ location }) => {
  return (
    <Layout>
      <Seo
        pathName={location.pathname}
        title='Integrated Quality (IQ) Process'
        description='We are software engineers, developers, designers, project managers, and outside-of-the-box thinkers. A one-stop-shop, you might say, and proud of it.'
      >
        <meta property='article:modified_time' content='2021-07-21T18:38:49+00:00' />
        <meta name='twitter:label1' content='Est. reading time' />
        <meta name='twitter:data1' content='17 minutes' />
      </Seo>

      <nav className='scroll-spy-nav scroll-spy-nav-iq-process'>
        <ul className='nav nav-pills flex-column'>
          <ScrollSpy>
            <SpyButton href='#Results' ref={React.createRef()}>
              <span className='nav-icon'>
                <ResultsIcon width={37} />
              </span>
              Results
            </SpyButton>
            <SpyButton href='#Onboarding' ref={React.createRef()}>
              <span className='nav-icon'>
                <OnboardingIcon width={37} />
              </span>
              Onboarding
            </SpyButton>
            <SpyButton href='#Estimation' ref={React.createRef()}>
              <span className='nav-icon'>
                <EstimationIcon width={37} />
              </span>
              Estimation
            </SpyButton>
            <SpyButton href='#Tempo' ref={React.createRef()}>
              <span className='nav-icon'>
                <TempoIcon width={37} />
              </span>
              Tempo
            </SpyButton>
            <SpyButton href='#Production' ref={React.createRef()}>
              <span className='nav-icon'>
                <ProductionIcon width={37} />
              </span>
              Production
            </SpyButton>
            <SpyButton href='#Framing' ref={React.createRef()}>
              <span className='nav-icon'>
                <FramingIcon width={37} />
              </span>
              Framing
            </SpyButton>
            <SpyButton href='#LiveDeploy' ref={React.createRef()}>
              <span className='nav-icon'>
                <LiveDeployIcon width={37} />
              </span>
              Live Deploy
            </SpyButton>
          </ScrollSpy>
        </ul>
      </nav>

      <section id='hero' className='bg-pattern-dotted iq-hero' style={{ backgroundSize: '170px' }}>
        <div className='d-flex align-items-stretch py-5'>
          <div className='d-flex align-items-center'>
            <span className='bg-primary flex-shrink-0 h-100 mr-4'></span>
            <h1 className='bg-primary text-white mb-0 mr-md-6 pt-4 pb-3 py-md-4 pl-4 px-md-4'>The 729 Solutions Integrated Quality (IQ) Process</h1>
          </div>
        </div>
      </section>

      <section className='py-6'>
        <div className='container pl-5 pr-6 px-md-5'>
          <div className='row'>
            <div className='col-md-4'>
              <h2>
                The IQ
                <br /> Process
              </h2>
              <StaticImage src='../images/iq-process/process.svg' alt='IQ Process Icons' className='mb-4' />
            </div>
            <div className='col-md-8'>
              <p>
                The IQ process is our customized process for software product development— a communication system built on top of the Agile methodology.
                <br />
                <br />
                It aligns the high-level executive strategy behind a project with the day-to-day tasks of product development in dynamic environments.
                <br />
                <br />
                So projects get finished on time, on budget, and take care of the problem they were designed to solve.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section id='Results' className='py-6 iq-section'>
        <div className='container pl-5 pr-6 px-md-5'>
          <div className='row'>
            <div className='col-md-4'>
              <h2>IQ: a proven process that gets you results</h2>
              <StaticImage src='../images/iq-process/process-2.svg' alt='IQ Process Icons' className='mt-4 mb-5' />
            </div>
            <div className='col-md-8'>
              <p>
                <strong>Every company starts with a problem.</strong>
                <br />
                <br />
                <strong>Ours was simple:</strong> we disagreed with the way a lot of projects were run at the time- bloated budgets and timelines, unclear
                communication, and ultimately, sub-par products or solutions.
                <br />
                <br />
                <strong>Could we do it better?</strong>
                <br />
                <br />
                When the customer doesn’t know what they don’t know…it’s easy for consultancies to sell them things they don’t need and churn out products or
                solutions that really don’t address the problems that the customer came to them with.
                <br />
                <br />
                We created a flexible velocity-based agile framework, (the Integrated Quality process) to guide customers past common pitfalls… so we’re able to
                complete higher-quality, better-performing projects on time and on budget.
              </p>
              <h3>The IQ process is how we do software development better.</h3>
              <p>
                It’s the best way to guarantee a common understanding and transparent communication between product team members and the key stakeholders of a
                project, so the things that matter most happen, and the things that don’t… get left behind.
                <br />
                <br />
                It’s constantly evolving to tell you the truth about what’s REALLY happening with your project. Most people can’t tell you honestly what’s going
                to happen with your project. We can.
                <br />
                <br />
                For example, we’ve been able to predict an 18-month project within two weeks’ accuracy.
                <br />
                <br />
                It’s the way we connect ALL the dots for our customers.
                <br />
                <br />
                <strong>And it’s how we end up saving our clients time, money, and frustration again and again.</strong>
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className='d-none d-md-block mt-6 bg-dark-transparent'>
        <div className='container pl-5 pr-6 px-md-5'>
          <div className='row pt-5'>
            <div className='col text-center'>
              <h2 className='text-white'>Phases Of The IQ Process</h2>
            </div>
          </div>
          <div className='row pb-5 iq-process-round-icons'>
            <div className='col-2'>
              <div className='iq-process-round-icon-step'>1</div>
              <div className='iq-process-round-icon tall-icon'>
                <StaticImage src='../images/iq-process/onboarding-phase.svg' alt='Onboarding Phase Icon' />
              </div>
            </div>
            <div className='col-2'>
              <div className='iq-process-round-icon-step'>2</div>
              <div className='iq-process-round-icon'>
                <StaticImage src='../images/iq-process/estimation-phase.svg' alt='Estimation Phase Icon' />
              </div>
            </div>
            <div className='col-2'>
              <div className='iq-process-round-icon-step'>3</div>
              <div className='iq-process-round-icon'>
                <StaticImage src='../images/iq-process/tempo-phase.svg' alt='Tempo Phase Icon' />
              </div>
            </div>
            <div className='col-2'>
              <div className='iq-process-round-icon-step'>4</div>
              <div className='iq-process-round-icon'>
                <StaticImage src='../images/iq-process/production-phase.svg' alt='Production Iteration Phase Icon' />
              </div>
            </div>
            <div className='col-2'>
              <div className='iq-process-round-icon-step'>5</div>
              <div className='iq-process-round-icon'>
                <StaticImage src='../images/iq-process/framing-phase.svg' alt='Framing Phase Icon' />
              </div>
            </div>
            <div className='col-2'>
              <div className='iq-process-round-icon-step'>6</div>
              <div className='iq-process-round-icon'>
                <StaticImage src='../images/iq-process/live-phase.svg' alt='Live Deploy Phase Icon' />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id='Onboarding' className='py-6 iq-section'>
        <div className='container pl-5 pr-6 px-md-5'>
          <div className='row'>
            <div className='col-4 mb-3'>
              <StaticImage src='../images/iq-process/onboarding-phase.svg' alt='Onboarding Phase Icon' />
            </div>
            <div className='col-md-8'>
              <h3>1. Onboarding Phase</h3>
              <h4>Get on the same page and set the team up for success</h4>
              <p>
                This checklist needs to be completed before any product development process can start.
                <br />
                <br />
                Line items include:
              </p>
              <ul>
                <li>Budget</li>
                <li>Time frame</li>
                <li>Metrics for success</li>
                <li>Stakeholders</li>
                <li>Product owner</li>
                <li>Roles and responsibilities</li>
              </ul>
              <p>
                Some of these items seem blindingly obvious, but in our experience working with other development agencies, we found that often when a project
                goes off the rails, it happens because it wasn’t well-scoped. That’s why{' '}
                <strong>we’re perfectionists when it comes to getting this right.</strong>
              </p>
            </div>
          </div>
        </div>
      </section>

      <section id='Estimation' className='py-6 iq-section'>
        <div className='container pl-5 pr-6 px-md-5'>
          <div className='row'>
            <div className='col-5 col-md-4 mb-3'>
              <StaticImage src='../images/iq-process/estimation-phase.svg' alt='Estimation Phase Icon' />
            </div>
            <div className='col-md-8'>
              <h3>2. Estimation Phase</h3>
              <h4>Research and discovery</h4>
              <p>Once we have the basics of your project scope, we perform significant research to fully understand:</p>
              <ul>
                <li>Primary user personas</li>
                <li>Recommended technical tools to use</li>
                <li>Estimated time needed</li>
                <li>A rough schedule of the project's major milestones (epics)</li>
                <li>A Functional Design Document</li>
              </ul>
              <p>We use a tool called Pivotal Tracker to help us estimate your project’s trajectory, so that any given time we’ll all be able to see:</p>
              <ul>
                <li>
                  <strong>How much work is left to do</strong>
                </li>
                <li>
                  <strong>How long it will take</strong>
                </li>
                <li>
                  <strong>How much more money it will cost to achieve the deliverables</strong>
                </li>
              </ul>
              <p>
                Pivotal Tracker divides the larger conceptual deliverables of a project into what they call ‘epics’. Epics are broken down into more granular
                tasks called ‘stories’.
                <br />
                <br />
                Each story is estimated by our engineers in points of difficulty. We do it this way because we found that hourly, Scrum-based estimates always
                change significantly as projects progress, and that we could increase our estimation accuracy exponentially by planning the work this way.
                <br />
                <br />
                <strong>We estimate that we’ve saved clients up to 25% of their project’s budget by estimating this way instead of statically.</strong>
                <br />
                <br />
                If applicable, this is also where we create:
              </p>
              <ul>
                <li>User flows</li>
                <li>Sitemaps</li>
                <li>Wireframes</li>
                <li>Mockups</li>
              </ul>
              <p>This phase helps us capture critical information that informs decision-making throughout the project.</p>
            </div>
          </div>
        </div>
      </section>

      <section id='Tempo' className='py-6 iq-section'>
        <div className='container pl-5 pr-6 px-md-5'>
          <div className='row'>
            <div className='col-5 col-md-4 mb-3'>
              <StaticImage src='../images/iq-process/tempo-phase.svg' alt='Tempo Phase Icon' />
            </div>
            <div className='col-md-8'>
              <h3>3. Tempo Phase</h3>
              <h4>Create the foundation, establish velocity, and build momentum</h4>
              <p>
                The first few iterations of any project are all about establishing a velocity standard and building the foundational components of the product.
                <br />
                <br />
                The goal here is to establish momentum, to hit the ground running, and to set a sustainable intensity that gives us clarity for the rest of the
                project.
                <br />
                <br />
                Within the first iteration or two, the team should have something functional, even if it’s basic.
                <br />
                <br />
                For really innovative technology, it can take multiple iterations to build that momentum,{' '}
                <strong>so we carefully monitor the project’s velocity and keep you in the loop every step of the way.</strong>
              </p>
            </div>
          </div>
        </div>
      </section>

      <section id='Production' className='py-6 iq-section'>
        <div className='container pl-5 pr-6 px-md-5'>
          <div className='row'>
            <div className='col-5 col-md-4 mb-3'>
              <StaticImage src='../images/iq-process/production-phase.svg' alt='Production Iteration Phase Icon' />
            </div>
            <div className='col-md-8'>
              <h3>4. Production Iteration Phases</h3>
              <h4>Estimate points, complete work, review, and adjust. Then, repeat</h4>
              <p>
                We use the backlog of stories that we created and prioritized to get to work. A product owner/ key stakeholder should be significantly involved,
                approving stories and collaborating with our team.
                <br />
                <br />
                The team will assign points to any new stories that are not yet pointed. Any issues or concerns about specific items are discussed, and the PM
                works with the product owner to prioritize or re-prioritize the stories if anything has changed.
                <br />
                <br />
                The product owner should be available throughout to address any questions or issues that are “blockers.”
                <br />
                <br />
                Unlike other development agencies, we don’t need to have long sprint-planning meetings, iteration reviews, or over-the-top PM costs.
                <br />
                <br />
                Why? Because our <strong>communication is built into every phase of the project and the way we approach the work every day.</strong>
              </p>
              <h6>Check-ins</h6>
              <p>
                In addition to the everyday contact we maintain with our clients, we schedule a weekly check-in to ensure alignment, pacing, and that production
                is on track.
                <br />
                <br />
                The product owner reviews the completed stories, addresses any questions, confirms work is on track, prioritizes deliverables, and makes course
                corrections if needed.
                <br />
                <br />
                During these meetings, the PM may demo the work to the product owner to assess completion, celebrate successes, and identify opportunities.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section id='Framing' className='py-6 iq-section'>
        <div className='container pl-5 pr-6 px-md-5'>
          <div className='row'>
            <div className='col-5 col-md-4 mb-3'>
              <StaticImage src='../images/iq-process/framing-phase.svg' alt='Framing Phase Icon' />
            </div>
            <div className='col-md-8'>
              <h3>5. Framing Phases</h3>
              <h4>Bridging the gap and keeping alignment</h4>
              <p>
                When the project is one-third finished, we have a meeting with the PM, any key stakeholders, and the product owner. We hold a similar meeting
                again at the two-thirds mark.
                <br />
                <br />
                The agendas for these meetings are to:
              </p>
              <ul>
                <li>Demo the latest version of the product</li>
                <li>Review the product roadmap</li>
                <li>Measure success against goals and any other criteria established</li>
                <li>Discuss any issues</li>
                <li>Readjust the roadmap based on new inputs or strategic goals</li>
                <li>Evaluate hours used and hours left in the budget</li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <section id='LiveDeploy' className='py-6 iq-section'>
        <div className='container pl-5 pr-6 px-md-5'>
          <div className='row'>
            <div className='col-5 col-md-4 mb-3'>
              <StaticImage src='../images/iq-process/live-phase.svg' alt='Live Deploy Phase Icon' />
            </div>
            <div className='col-md-8'>
              <h3>6. Live Deploy Phase</h3>
              <h4>Success!</h4>
              <p>
                When the product is finished, we finalize any documentation of what has been done.
                <br />
                <br />
                Then, the PM will hold a final presentation meeting to:
              </p>
              <ul>
                <li>Demonstrate the product</li>
                <li>Revisit criteria and goals that were set throughout the project and how they were achieved</li>
                <li>Answer any questions</li>
                <li>Note any final tweaks that need to be made</li>
                <li>Readjust the roadmap based on new inputs or strategic goals</li>
                <li>Celebrate!</li>
              </ul>
              <p>
                We also provide any training or hand-over support our clients need, so we can ensure that your new product immediately starts making a positive
                impact on your business.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className='mt-6 bg-dark-transparent'>
        <div className='container pl-5 pr-6 px-md-5'>
          <div className='row py-5'>
            <div className='col-md-4'>
              <h2 className='text-white'>Seamless Production With The IQ Process</h2>
            </div>
            <div className='col-md-8 text-white'>
              <p>
                We’ve seen it too many times…in a lot of companies and development agencies, there’s a huge disconnect between developers and key stakeholders.
                <br />
                <br />
                Developers are often given tight deadlines without an understanding of how their work contributes to a product’s success. So then what happens?
                <br />
                <br />
                They end up building unnecessary features in a silo, plowing through time and budget, and not producing the solution that project NEEDS.
                <br />
                <br />
                We’ve gotten rid of this disconnect by creating strong communication systems between product owners and our development team. The IQ process
                lets you:
              </p>
              <ul>
                <li>Accomplish strategic goals faster and more effectively</li>
                <li>Have clear visibility of the product development process</li>
                <li>Hit targets and deadlines quickly</li>
                <li>Keep projects on time and on budget</li>
              </ul>
              <p>
                Here at 729, our mission is to manage and cultivate creativity in businesses with meaningful and measurable results. We do that by helping
                companies produce better software with less frustration and more efficiency.
              </p>
            </div>
          </div>
        </div>
      </section>
      <NextProject className='next-project mb-5' />
    </Layout>
  )
}

export default IQProcess
