import React from "react";
import CTA from "./cta";
import RightHandIcon from "../icons/right-hand.svg";

const NextProject = ({ className }) => {
  return (
    <section className={className}>
      <div className="container text-left">
        <div className="row text-white py-5 py-md-6">
          <div className="col d-flex flex-column flex-md-row justify-content-lg-center">
            <div className="icon-row">
              <RightHandIcon className="icon" alt="Imagine Your Next Project" />
            </div>
            <div>
              <p className="title">Imagine Your Next Project…</p>
              <p className="subtitle">What will it mean to you:</p>
              <ul>
                <li>
                  To have it done by a development company of reliable experts?
                </li>
                <li>To have it finished on time and on budget?</li>
                <li>To have it perform better than you thought possible?</li>
              </ul>
              <p className="subtitle">
                Because that’s what you get when you work with us. Reach out
                today.
              </p>
              <div className="btn-letchat text-center text-md-left">
                <CTA
                  href="/contact-us/"
                  text="LET'S CHAT"
                  className="btn btn-tertiary"
                ></CTA>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default NextProject;
