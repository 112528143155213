import React from "react";
import { AnchorLink } from "gatsby-plugin-anchor-links";

const SpyButton = React.forwardRef(({ href, children }, ref) => (
  <li className="nav-item" ref={ref}>
    <AnchorLink to={href} className="text-white">
      {children}
    </AnchorLink>
  </li>
));

export default SpyButton;